import React from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import { useCountdown } from './hooks/useCountdown';

const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <span>Expired!!!</span>
      <p>Please select a future date and time.</p>
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="show-counter">  
      <DateTimeDisplay value={days} type={'Days'} sep={':'} isDanger={days <= 3} />  
      <DateTimeDisplay value={hours} type={'Hours'} sep={':'} isDanger={false} />
      <DateTimeDisplay value={minutes} type={'Mins'} sep={':'} isDanger={false} />    
      <DateTimeDisplay value={seconds} type={'Seconds'} sep={''} isDanger={false} />
  </div>
  );
};

const CountdownTimer = ({ targetDate }) => {

  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={("0" + days).slice(-2)}
        hours={("0" + hours).slice(-2)}
        minutes={("0" + minutes).slice(-2)}
        seconds={("0" + seconds).slice(-2)}
      />
    );
  }
};

export default CountdownTimer;
