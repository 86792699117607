import React from 'react';

const DateTimeDisplay = ({ value, type, isDanger, sep }) => {
  return (
    <div style={{padding:"10px"}} className={isDanger ? 'countdown danger' : 'countdown'}>
      <span className='timer-val'>{value}</span>
      <span className='timer-dot'>{sep}</span><br/>
      <span className='time-desc'>{type}</span>
    </div>
  );
};

export default DateTimeDisplay;
