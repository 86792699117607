import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import CountdownTimer from './CountdownTimer';
import SetupTimer from './Admin/SetupTimer';
import '../src/App.css';

export default function App() {

 

  const [countdownTime, setCountdownTime] = useState('')
  useEffect(()=>{
     var sdata= new Date();
    var endDate = sdata.setDate( sdata.getDate() + + process.env.REACT_APP_TIMER_ENDS_ON_DAYS )
    setCountdownTime(endDate);
  
  },[]) 
  


  return (
    <>
    <BrowserRouter>
        <Switch>
          <Route path="/">
          {countdownTime!=''?(
            <>
            
            <div id="outerPanel" className='container-fluid bg-dark p-0'>
              {/* <img id='videoPanelAnimation' style={{width:"100%", height:"100%",  position:"fixed"}} src='/0371.gif' /> */}
            <video id='videoPanelAnimation'  src="/202308031419.mp4" loop autoPlay muted style={{width:"100%", height:"100%", position:"fixed", backgroundColor:"black"}} ></video>             
               <div id='counterPanel'>
                <CountdownTimer targetDate={countdownTime} />
              </div>
            </div></>):<></>}
          </Route>
         
        </Switch>
      </BrowserRouter>
    
    </>
  );
}
